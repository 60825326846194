import { Icon, LogoSVG } from "@sermo/ui-components";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useRef, useEffect } from "react";
import { Link, NavLink, Route, Routes, useLocation, useMatch } from "react-router-dom";
import AdPreviewMobileTopNav from "@components/AdPreviewLayout/AdPreviewMobileTopNav";
import AdPreviewNavMenu from "@components/AdPreviewLayout/AdPreviewNavMenu";
import { TabletLandscapeAndDesktop } from "@components/MediaQueries/MediaQueries";
import MobileTopNav from "@components/Navs/components/MobileTopNav";
import { ResourcePreviewNavMenu } from "@components/ResourcePreview";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useUserDefaultRoute, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import typography from "../../scss/typography.scss";
import NavMenu from "../NavMenu/NavMenu";
import Search from "../Search/SearchInput";
import styles from "./TopNav.scss";

const LOGO_HEIGHT = 24;
const LOGO_WIDTH = 77;

const Login = ({ locale, queryParams, search }) => {
	const trackEvent = useTrackEvent();
	const onClick = () => {
		trackEvent({
			category: "top-nav",
			action: "click",
			label: "login-clicked",
			queryParams: queryParams,
			locale: locale,
		});
	};

	return (
		<NavLink
			styleName={classNames("styles.top-nav--logged-out--top-link","typography.body-short-brand-medium-03")}
			to={"/login" + search}
			onClick={onClick}
		>
			{getTranslation("frontend.login.topNavigationLogInLinkText", true)}
		</NavLink>
	);
};

Login.propTypes = {
	locale: PropTypes.string,
	queryParams: PropTypes.object,
	search: PropTypes.string,
};

const Register = ({ locale, queryParams, affiliation, search }) => {
	const trackEvent = useTrackEvent();

	const onClick = () => {
		trackEvent({
			category: "top-nav",
			action: "click",
			label: "register-clicked",
			queryParams: queryParams,
			locale: locale,
		});
	};

	return (
		<NavLink
			styleName={classNames("styles.top-nav--logged-out--top-link","typography.body-short-brand-medium-03")}
			to={"/register/start" + search}
			onClick={onClick}
		>
			{getTranslation("frontend.login.topNavigationSignUpLinkText", true)}
		</NavLink>
	);
};

Register.propTypes = {
	locale: PropTypes.string,
	queryParams: PropTypes.object,
	search: PropTypes.string,
	affiliation: PropTypes.string,
};

const Empty = () => <></>;

const LogoImage = () => (
	<Icon
		src={LogoSVG}
		width={LOGO_WIDTH}
		height={LOGO_HEIGHT}
	/>
);

export const navRestrictedRoutesRegex = /\/(firsttimelogin|runwithpeter)\/?/;

/**
 * Renders the top nav of the site including logo, search, sections and profile
 * @name TopNav
 * @param {*} param0
 * @example <TopNav section="feed"/>
 */
const TopNav = ({ section }) => {
	const el = useRef();
	const isAdPreview = !!useMatch("/ad-preview/*");
	const isDMAPreview = !!useMatch("/dma-preview/*");
	const isOtherPreview = !!useMatch("/preview/*");
	const isPreview = isAdPreview || isDMAPreview || isOtherPreview;
	const showTopNav = !navRestrictedRoutesRegex.test(useLocation().pathname);
	const trackEvent = useTrackEvent();
	const logoUrl = useUserDefaultRoute();

	const {
		locale,
		queryParams,
	} = useMemoizedContext("member", [
		"locale",
		"queryParams",
	]);

	const {
		loggedIn,
		hasAcceptedTermsAndConditions,
		brandAdministrator,
		id,
		affiliation,
		trialMember,
		affiliateMember,
		readOnlyMember,
		showLeadLandingPage,
		isPostSurveyReferral,
		wasPostSurveyReferralVerified,
		showFirstSurveyPendingVerificationPage,
	} = useMemoizedContext("member", [
		"loggedIn",
		"hasAcceptedTermsAndConditions",
		"brandAdministrator",
		"id",
		"affiliation",
		"trialMember",
		"affiliateMember",
		"readOnlyMember",
		"showLeadLandingPage",
		"isPostSurveyReferral",
		"wasPostSurveyReferralVerified",
		"showFirstSurveyPendingVerificationPage",
	]);

	const {
		expandedPost,
	} = useMemoizedContext("ui", [
		"expandedPost",
	]);

	const onTouchMove = (e) => {
		if (expandedPost) {
			e.preventDefault();
			e.stopPropagation();
		}
	};

	useEffect(() => {
		let element = el.current;
		element.addEventListener("touchmove", onTouchMove, { passive: false });
		return () => {
			element.removeEventListener("touchmove", onTouchMove, { passive: false });
		};
	}, []);

	let search = window.location.search;

	if (!search || search.length <= 0) {
		if (affiliation === "mnow") {
			search = "?utm_campaign=mnowapp";
		}
		else {
			search = "?utm_campaign=sermoapp";
		}
	} else if (search.indexOf("utm_campaign") < 0) {
		if (affiliation === "mnow") {
			search += "&utm_campaign=mnowapp";
		}
		else {
			search += "&utm_campaign=sermoapp";
		}
	}

	const logoClicked = (url) => {
		trackEvent({
			category: "top-nav",
			action: "click",
			label: "logo-clicked",
			url: url,
			queryParams: queryParams,
			locale: locale,
		});
	};

	return (
		<Routes>
			<Route
				path="*"
				element={
					<header
						data-component={"TopNav"}
						styleName={
							classNames([
								"styles.top-nav",
								{ "styles.top-nav--logged-out": !loggedIn && !isPreview },
							])
						}
						ref={el}
					>
						<div styleName="styles.top-nav-wrapper">
							<Routes>
								{
									isAdPreview && (
										<Route
											path="/ad-preview/*"
											element={
												<>
													<TabletLandscapeAndDesktop>
														<div styleName="styles.left-wrapper">
															<div styleName="styles.logo">
																<LogoImage />
															</div>
														</div>
													</TabletLandscapeAndDesktop>
													<AdPreviewNavMenu />
													<AdPreviewMobileTopNav />
												</>
											}
										/>
									)
								}
								{
									isDMAPreview && (
										<Route
											path="/dma-preview/*"
											element={
												<>
													<TabletLandscapeAndDesktop>
														<div styleName="styles.left-wrapper">
															<div styleName="styles.logo">
																<LogoImage />
															</div>
														</div>
													</TabletLandscapeAndDesktop>
													<AdPreviewNavMenu />
													<AdPreviewMobileTopNav />
												</>
											}
										/>
									)
								}
								{
									isOtherPreview && (
										<Route
											path="/preview/*"
											element={
												<>
													<TabletLandscapeAndDesktop>
														<div styleName="styles.left-wrapper">
															<div styleName="styles.logo">
																<LogoImage />
															</div>
														</div>
													</TabletLandscapeAndDesktop>
													<ResourcePreviewNavMenu />
												</>
											}
										/>
									)
								}
							</Routes>
							{
								!isPreview
								&& loggedIn
								&& (
									<>
										<TabletLandscapeAndDesktop>
											<div styleName="styles.left-wrapper">
												<div styleName="styles.logo">
													{
														brandAdministrator && (
															<NavLink
																to={`/profile/${id}/overview`}
																onClick={
																	() => {
																		logoClicked(`/profile/${id}/overview`);
																	}
																}
															>
																<LogoImage />
															</NavLink>
														)
													}
													{
														!brandAdministrator && (
															<Link
																to={logoUrl}
																onClick={
																	() => {
																		logoClicked(logoUrl);
																	}
																}
															>
																<LogoImage />
															</Link>
														)
													}
												</div>
												{
													(
														hasAcceptedTermsAndConditions
														|| trialMember
														|| affiliateMember
														|| readOnlyMember
														|| isPostSurveyReferral
														|| wasPostSurveyReferralVerified
													)
													&& !showLeadLandingPage
													&& !showFirstSurveyPendingVerificationPage
													&& showTopNav && <Search />
												}
											</div>
										</TabletLandscapeAndDesktop>
										{
											(
												hasAcceptedTermsAndConditions
												|| trialMember
												|| affiliateMember
												|| readOnlyMember
												|| isPostSurveyReferral
												|| wasPostSurveyReferralVerified
											)
											&& !showLeadLandingPage
											&& !showFirstSurveyPendingVerificationPage
											&& showTopNav
											&& (
												<>
													<MobileTopNav />
													<TabletLandscapeAndDesktop>
														<NavMenu section={section} />
													</TabletLandscapeAndDesktop>
												</>
											)
										}
									</>
								)
							}
							{
								!isPreview
								&& !loggedIn
								&& (
									<>
										<div styleName="styles.left-wrapper">
											<div styleName="styles.logo">
												<Routes>
													<Route
														path="/barometer/*"
														element={
															<a
																href="https://www.sermo.com/about?utm_campaign=sermoapp"
																onClick={
																	() => {
																		logoClicked("https://www.sermo.com/about");
																	}
																}
															>
																<LogoImage />
															</a>
														}
													/>
													<Route
														path="*"
														element={
															<NavLink
																to="/login?utm_campaign=sermoapp"
																onClick={
																	() => {
																		logoClicked("/login");
																	}
																}
															>
																<LogoImage />
															</NavLink>
														}
													/>
												</Routes>
											</div>
										</div>
										<div styleName="styles.top-nav--logged-out--wrapper">
											<Routes>
												<Route
													path="/barometer/*"
													element={
														<>
															<Login
																locale={locale}
																queryParams={queryParams}
																search={search}
															/>
															<span styleName="styles.login-or-register-divider">|</span>
															<Register
																locale={locale}
																queryParams={queryParams}
																search={search}
																affiliation={affiliation}
															/>
														</>
													}
												/>
												<Route
													path="/event/:eventToken"
													element={
														<>
															<Login
																locale={locale}
																queryParams={queryParams}
																search={search}
															/>
															<span styleName="styles.login-or-register-divider">|</span>
															<Register
																locale={locale}
																queryParams={queryParams}
																search={search}
																affiliation={affiliation}
															/>
														</>
													}
												/>
												<Route
													path="/register/:subRoute"
													element={
														<Login
															locale={locale}
															queryParams={queryParams}
															search={search}
														/>
													}
												/>
												<Route
													path="/unsubscribe/*"
													element={
														<Login
															locale={locale}
															queryParams={queryParams}
															search={search}
														/>
													}
												/>
												<Route
													path="/login/blocked"
													exact
													element={<Empty />}
												/>
												<Route
													path="/login/*"
													element={
														<Register
															locale={locale}
															queryParams={queryParams}
															search={search}
															affiliation={affiliation}
														/>
													}
												/>
											</Routes>
										</div>
									</>
								)
							}
						</div>
					</header>
				}
			/>
		</Routes>
	);
};

TopNav.propTypes = {
	section: PropTypes.string,
};

export default TopNav;
