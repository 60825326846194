import { useState, useEffect, useRef } from "react";
import { useLocation, useMatch, useRoutes } from "react-router-dom";
import { usePrevious } from "react-use";
import BrowseTopics from "@components/Modal/Modals/BrowseTopics/BrowseTopics";
import GenericConfirmModal from "@components/Modal/Modals/GenericConfirmModal";
import InviteColleaguesModal from "@components/Modal/Modals/InviteColleagues/InviteColleaguesModal";
import { useOpenPartnerModal } from "@components/Modal/Modals/PartnerModal/PartnerModal";
import PendingAccountDeactivationModal from "@components/Modal/Modals/PendingAccountDeactivationModal";
import PostSurveyBanner from "@components/Modal/Modals/PostSurveyBanner";
import PrivacyPolicy from "@components/Modal/Modals/PrivacyPolicy";
import RatingModal from "@components/Modal/Modals/RatingModal";
import Reverify from "@components/Modal/Modals/Reverify/Reverify";
import SetPasswordAndAcceptTermsModal from
	"@components/Modal/Modals/SetPasswordAndAcceptTermsModal/SetPasswordAndAcceptTermsModal";
import { ReadOnlyModal } from "@components/ReadOnly";
import PostSurvey from "@components/Register/PostSurvey";
// import { RunWithPeterModal } from "@components/RunWithPeter/RunWithPeterModal";
import {EUIKey, useCloseBanner, useCloseModal, useOpenBanner, useOpenModal, useTogglePostEditor, useToggleReloadTour} from "@contexts/UI";
import { useTrackEvent } from "@frontend/tracking/tracking";
import {
	fetchUrl, isNotToday,
	removeParamFromUrl,
	removeParamsFromUrl,
	SEEN_TRIAL,
	usePreconditionModal
} from "@frontend/Utils";
// import { deleteCookie, getCookie, setCookie } from "@frontend/Utils";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";

const ShowRateModal = () => {
	const closeModal = useCloseModal();
	const openModal = useOpenModal();

	const {
		modalIsOpen,
	} = useMemoizedContext("ui", [
		"modalIsOpen",
	]);

	const {
		countryCode,
		icdCodes,
		locale,
		primarySpecialtyId,
		m2h,
		id,
		hasConfiguredProfile,
		showTourGuide,
		hasSetPassword,
		hasAcceptedTermsAndConditions,
		hasAccess,
		trialMember,
		affiliateMember,
	} = useMemoizedContext("member", [
		"countryCode",
		"icdCodes",
		"locale",
		"primarySpecialtyId",
		"m2h",
		"id",
		"hasConfiguredProfile",
		"showTourGuide",
		"hasSetPassword",
		"hasAcceptedTermsAndConditions",
		"brandAdministrator",
		"trialMember",
		"affiliateMember",
		"showLeadLandingPage",
		"showFirstSurveyPendingVerificationPage",
	]);

	let params = "";

	const addParam = param => {
		const char = params.length === 0
			? "?"
			: "&";

		return params + char + param;
	};

	if (countryCode) {
		params = addParam(`country_of_practice_code=${countryCode}`);
	}

	if (locale) {
		// Ratings are working a bit inconsistent. Instead of always using ISO 4 locale,
		// it uses ISO 2 for the majority, but ISO 4 for a few specific languages.
		if (locale === "en-GB" || locale === "es-MX" || locale === "pt-BR") {
			params = addParam(`locale=${locale}`);
		} else {
			params = addParam(`locale=${locale.substring(0, 2)}`);
		}
	}

	if (primarySpecialtyId) {
		params = addParam(`primary_specialty_id=${primarySpecialtyId}`);
	}

	if (m2h) {
		params = addParam(`m2_uhash=${escape(m2h)}`);
	}

	if (id) {
		params = addParam(`m2_u=${id}`);
	}

	try {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const queryParams = Object.fromEntries(urlSearchParams.entries());

		if (typeof queryParams !== "undefined") {
			if (queryParams["utm_source"] !== undefined) {
				params = addParam(`utm_source=${queryParams["utm_source"]}`);
			}
			if (queryParams["utm_medium"] !== undefined) {
				params = addParam(`utm_medium=${queryParams["utm_medium"]}`);
			}
			if (queryParams["utm_campaign"] !== undefined) {
				params = addParam(`utm_campaign=${queryParams["utm_campaign"]}`);
			}
			if (queryParams["utm_term"] !== undefined) {
				params = addParam(`utm_term=${queryParams["utm_term"]}`);
			}
			if (queryParams["utm_content"] !== undefined) {
				params = addParam(`utm_content=${queryParams["utm_content"]}`);
			}
		}
	} catch (e) {
		console.error(e);
	}

	params += "&app=m2";

	if (icdCodes) {
		if (typeof icdCodes.split !== "undefined") {
			icdCodes.split(",").forEach(code => {
				params = addParam(`icd10_codes%5B%5D=${code}`);
			});
		}
		else {
			icdCodes.forEach(code => {
				params = addParam(`icd10_codes%5B%5D=${code}`);
			});
		}
	}

	let drugRatingsUrl = `${window.sermo.drugRatingsEndpointUrl}ratings_app/display${params}`;

	const showModal = url => {
		openModal({
			[EUIKey.MODAL_COMPONENT]: <RatingModal url={url} />,
			[EUIKey.MODAL_LABEL]: "rating-modal",
			[EUIKey.MODAL_TEXT_LABEL]: "Drug Ratings, by doctors for doctors.",
		});
	};

	const trackEvent = useTrackEvent();
	const toggleReloadTour = useToggleReloadTour();

	useEffect(() => {
		if (
			hasAccess("canAccessFrontendDrugRatings", false)
			&& hasAccess("canRateDrugsInDrugRatings", false)
			&& icdCodes?.length
			&& primarySpecialtyId
			&& hasConfiguredProfile
			&& !showTourGuide
			&& hasSetPassword
			&& hasAcceptedTermsAndConditions
			&& !trialMember
			&& !affiliateMember
			&& !modalIsOpen
		) {
			const receiveMessage = (event) => {
				if (event.origin !== window.sermo.drugRatingsEndpointUrl && event.data === "close") {
					closeModal("rating-modal");

					trackEvent({
						category: "drugratingsmodal",
						label: "ratings",
						action: "close",
						locale: locale,
					});

					// -- Force reload tour to verify if the tour modal should open -- //
					toggleReloadTour();
				}
			}

			window.addEventListener("message", receiveMessage, false);

			fetchUrl(drugRatingsUrl, false).then(dataResponse => {
				if (dataResponse.ok) {
					dataResponse.json().then(body => {
						switch (body.show) {
							case "show_welcome_page":
								trackEvent({
									category: "drugratingsmodal",
									label: "welcomepage",
									action: "open",
									locale: locale,
									url: `${window.sermo.drugRatingsEndpointUrl}welcome${params}`,
								});
								showModal(`${window.sermo.drugRatingsEndpointUrl}welcome${params}`);
								break;
							case "show_rating_form":
								trackEvent({
									category: "drugratingsmodal",
									label: "ratingspage",
									action: "open",
									locale: locale,
									url: `${window.sermo.drugRatingsEndpointUrl}${params}`,
								});
								showModal(`${window.sermo.drugRatingsEndpointUrl}${params}`);
								break;
							case "do_not_show":
							default:
								return;
						}
					});
				} else {
					console.error("Error fetching drug ratings data", dataResponse);
				}
			}).catch(error => {
				console.error("Error fetching drug ratings data", error);
			});
		}
	}, []);

	return null;
};

const AutomationRoutes = () => {
	const element = <ShowRateModal />;
	const paths = ["/feed/*", "/post/*", "/surveys/*", "/activity/*", "/messages/*", "/profile/*", "/settings/*", "/resource/*"];
	const routes = paths.map(path => {return {
		path,
		element,
	}});
	return useRoutes(routes);
};

const readOnlyExcludedPathsRegex = /^\/(runwithpeter|ad-preview|barometer|read-only|firsttimelogin|accepttermsandconditions)/;

const Automation = () => {
	const apiEndpoint = useApiEndpoint();
	const closeBanner = useCloseBanner();
	const closeModal = useCloseModal();
	const location = useLocation();
	const isReadOnlyExcludedPath = readOnlyExcludedPathsRegex.test(location.pathname);
	const matchEvents = useMatch("/event/*");
	const openBanner = useOpenBanner();
	const openModal = useOpenModal();
	const proceed = useRef(true);
	const trackEvent = useTrackEvent();

	const {
		bannerIsOpen,
		bannerLabel,
		modalIsOpen,
		modalLabel,
	} = useMemoizedContext("ui", [
		"bannerIsOpen",
		"bannerLabel",
		"modalIsOpen",
		"modalLabel",
	]);

	const {
		locale,
		hasPendingDeactivationRequest,
		trialMember,
		affiliateMember,
		hasSeenTrialMemberPopup,
		hasSeenPostSurveyReferralPopup,
		showLeadLandingPage,
		updateMember,
		queryParams,
		isReverificationRequired,
		isPostSurveyReferral,
		wasPostSurveyReferralVerified,
		hasSetPassword,
		readOnlyMember,
		privacyPolicy,
		showFirstSurveyPendingVerificationPage,
	} = useMemoizedContext("member", [
		"locale",
		"hasPendingDeactivationRequest",
		"trialMember",
		"affiliateMember",
		"hasSeenTrialMemberPopup",
		"hasSeenPostSurveyReferralPopup",
		"showLeadLandingPage",
		"queryParams",
		"isReverificationRequired",
		"isPostSurveyReferral",
		"wasPostSurveyReferralVerified",
		"hasSetPassword",
		"readOnlyMember",
		"privacyPolicy",
		"showFirstSurveyPendingVerificationPage",
	]);

	useEffect(() => {
		trackEvent({
			category: "automation",
			label: "page",
			action: "load",
		});
	}, [
		location.pathname,
	]);

	useEffect(() => {
		updateMember({
			queryParams: Object.fromEntries(new URLSearchParams(location.search).entries()),
		});
	}, [
		location.search,
	]);

	// prevent double tracking calls
	const previousLocation = usePrevious(location);
	const previousPathname = usePrevious(location?.pathname);
	useEffect(() => {
		if (
			location?.search !== previousLocation?.search
			|| previousPathname !== location?.pathname
		) {
			const trackedRoutes = ["/rate", "/barometer", "/login", "/resource"];
			const shouldTrack = trackedRoutes.find(route => location.pathname.includes(route));
			shouldTrack && apiEndpoint("system/logroutechange", { route: location.pathname });
		}
	}, [apiEndpoint, location]);

	const [showMemberDeactivationCanceledConfirmModal, setShowMemberDeactivationCanceledConfirmModal] = useState(false);
	const [showTrialMemberModal, setShowTrialMemberModal] = useState(true);
	const [showPostSurveyReferralVerificationModal, setShowPostSurveyReferralVerificationModal] = useState(true);

	const { openPreconditionModal } = usePreconditionModal((type, data) => {
		setShowTrialMemberModal(false);
		if (data.state === "state1") {
			// First time closed
			window.trialMemberPopupCallbackTimer = setTimeout(() => {
				openPreconditionModal();
				clearTimeout(window.trialMemberPopupShowTimer);
			}, 10000);
		}
	});

	// Post Survey Referral
	useEffect(() => {
		if (
			wasPostSurveyReferralVerified
			&& !hasSetPassword
			&& !modalIsOpen
		) {
			openModal({
				[EUIKey.MODAL_CAN_CLOSE]: false,
				[EUIKey.MODAL_COMPONENT]: <SetPasswordAndAcceptTermsModal />,
				[EUIKey.MODAL_LABEL]: "setPasswordAndAcceptTermsModal",
			});
		}

		if (
			wasPostSurveyReferralVerified
			&& hasSetPassword
			&& modalIsOpen
			&& modalLabel === "setPasswordAndAcceptTermsModal"
		) {
			closeModal("setPasswordAndAcceptTermsModal");
		}
	}, [
		hasSetPassword,
		modalIsOpen,
		modalLabel,
		wasPostSurveyReferralVerified,
	]);

	useEffect(() => {
		if (
			isPostSurveyReferral
			&& !bannerIsOpen
		) {
			let currentUrl = window.location.pathname.toLowerCase();

			if (parseInt(currentUrl.indexOf("/firsttimelogin")) < 0) {
				openBanner({
					[EUIKey.BANNER_CAN_CLOSE]: false,
					[EUIKey.BANNER_COMPONENT]: (
						<PostSurveyBanner/>
					),
					[EUIKey.BANNER_LABEL]: "post-survey-referral-banner",
					[EUIKey.BANNER_SUBTYPE]: "information-darker",
				});
			}
		}

		if (
			!isPostSurveyReferral
			&& bannerIsOpen
			&& bannerLabel === "post-survey-referral-banner"
		) {
			closeBanner("post-survey-referral-banner");
		}
	}, [
		isPostSurveyReferral,
		bannerIsOpen,
		bannerLabel,
	]);

	// Read Only Member
	useEffect(() => {
		const label = "read-only-modal";
		if (
			readOnlyMember
			&& !isReverificationRequired
			&& !modalIsOpen
			&& !isReadOnlyExcludedPath
			&& !showFirstSurveyPendingVerificationPage
		) {
			openModal({
				[EUIKey.MODAL_CAN_CLOSE]: false,
				[EUIKey.MODAL_COMPONENT]: <ReadOnlyModal />,
				[EUIKey.MODAL_LABEL]: label,
			});
		} else if (
			modalIsOpen
			&& label === modalLabel
			&& isReadOnlyExcludedPath
		) {
			closeModal(label);
		}
	}, [
		closeModal,
		isReadOnlyExcludedPath,
		isReverificationRequired,
		location.pathname,
		modalLabel,
		modalIsOpen,
		openModal,
		readOnlyMember,
		showFirstSurveyPendingVerificationPage,
	]);

	const closePendingDeactivationRequestModalEventHandler = (success) => {
		if (success) {
			closeBanner("deactivate-alert-banner");
			setShowMemberDeactivationCanceledConfirmModal(true);
		}
	};

	useEffect(() => {
		let searchParams = new URLSearchParams(window.location.search);
		if (
			queryParams?.showmodal === "browsetopics"
			&& !modalIsOpen
		) {
			// update url to not show the param
			const newUrl = removeParamFromUrl(window.location.href, "showmodal")
			window.history.replaceState(null, "", newUrl);

			// remove it from the browsers params
			searchParams.delete("showmodal");

			// delete it from the context
			delete queryParams.showmodal;
			updateMember({ queryParams });

			openModal({
				[EUIKey.MODAL_COMPONENT]: <BrowseTopics closeModalEventHandler={() => closeModal("browse-topics")} />,
				[EUIKey.MODAL_LABEL]: "browse-topics",
				[EUIKey.MODAL_SUBTYPE]: "browseTopics",
				[EUIKey.MODAL_TEXT_LABEL]: getTranslation("frontend.feedInfo.browseTopics"),
			});
		}
	}, [
		modalIsOpen,
		queryParams?.showmodal,
	]);

	useEffect(() => {
		if (
			(
				[
					"invitecolleagues",
					"invitecolleaguesemail",
					"invitecolleagueslink"
				].includes(queryParams?.showmodal?.toLowerCase())
				|| queryParams?.invite === "true"
			)
			&& !modalIsOpen
		) {
			let searchParams = new URLSearchParams(window.location.search);
			searchParams.delete("invite");
			searchParams.delete("showmodal");

			// update url to not show the param
			const newUrl = removeParamsFromUrl(window.location.href, ["showmodal", "invite"])
			window.history.replaceState(null, "", newUrl);

			// remove it from the browsers params
			searchParams.delete("showmodal");
			searchParams.delete("invite");

			// delete it from the context
			delete queryParams.showmodal;
			delete queryParams.invite;
			updateMember({ queryParams });

			openModal({
				[EUIKey.MODAL_COMPONENT]: (
					<InviteColleaguesModal
						closeModalEventHandler={() => closeModal("invite-colleagues")}
						inviteType={queryParams?.showmodal}
					/>
				),
				[EUIKey.MODAL_LABEL]: "invite-colleagues",
				[EUIKey.MODAL_TEXT_LABEL]: getTranslation("frontend.modals.inviteColleagues.title"),
			});
		}
	}, [
		modalIsOpen,
		queryParams?.invite,
		queryParams?.showmodal,
	]);

	const [openPartnerModal] = useOpenPartnerModal(queryParams.partnerModal);

	// use previous to ensure we don't automatically open the modal after the initial render
	const previousQueryParams = usePrevious(queryParams);

	useEffect( () => {
		if (
			queryParams !== previousQueryParams
			&& queryParams?.partnerModal
		) {
			openPartnerModal();
		}
	}, [location]);

	useEffect(() => {
		if (
			proceed.current
			&& hasPendingDeactivationRequest
			&& !bannerIsOpen
		) {
			openBanner({
				[EUIKey.BANNER_CAN_CLOSE]: false,
				[EUIKey.BANNER_CLOSE_METHOD]: closePendingDeactivationRequestModalEventHandler,
				[EUIKey.BANNER_COMPONENT]: (
					<PendingAccountDeactivationModal
						closeModalEventHandler={closePendingDeactivationRequestModalEventHandler}
					/>
				),
				[EUIKey.BANNER_LABEL]: "deactivate-alert-banner",
				[EUIKey.BANNER_SUBTYPE]: "error",
			});
		}
	}, [
		bannerIsOpen,
		hasPendingDeactivationRequest,
	]);

	useEffect(() => {
		if (
			isReverificationRequired
			&& !bannerIsOpen
		) {
			openBanner({
				[EUIKey.BANNER_CAN_CLOSE]: false,
				[EUIKey.BANNER_CLOSE_METHOD]: () => {},
				[EUIKey.BANNER_COMPONENT]: (
					<Reverify />
				),
				[EUIKey.BANNER_LABEL]: "reverification-alert-banner",
				[EUIKey.BANNER_SUBTYPE]: "error",
			});
		}
		if (
			!isReverificationRequired
			&& bannerIsOpen
			&& bannerLabel === "reverification-alert-banner"
		) {
			closeBanner("reverification-alert-banner");
		}
	}, [
		bannerIsOpen,
		bannerLabel,
		isReverificationRequired,
	]);

	const closeCancelPendingDeactivationConfirmModalEventHandler = () => {
		closeModal("deactivate-confirmation");
		setShowMemberDeactivationCanceledConfirmModal(false);
	};

	useEffect(() => {
		if (proceed.current && showMemberDeactivationCanceledConfirmModal) {
			if (!modalIsOpen) {
				openModal({
					[EUIKey.MODAL_CLOSE_METHOD]: closeCancelPendingDeactivationConfirmModalEventHandler,
					[EUIKey.MODAL_COMPONENT]: (
						<GenericConfirmModal
							submitEventHandler={closeCancelPendingDeactivationConfirmModalEventHandler}
							text={getTranslation("frontend.modals.accountDeactivationCanceledConfirm.content")}
							submitText={getTranslation("frontend.generics.OK")}
						/>
					),
					[EUIKey.MODAL_LABEL]: "deactivate-confirmation",
					[EUIKey.MODAL_TEXT_LABEL]: getTranslation("frontend.modals.accountDeactivationCanceledConfirm.title"),
				});
			}
		}
	}, [
		modalIsOpen,
		showMemberDeactivationCanceledConfirmModal,
	]);

	// Start the timer to show the trial member popup after x seconds
	useEffect(() => {
		if (
			!hasSeenTrialMemberPopup
			&& (trialMember || affiliateMember)
			&& showTrialMemberModal
			&& !location.pathname.toLowerCase().startsWith("/firsttimelogin")
			&& !matchEvents
			&& !modalIsOpen
			&& !showLeadLandingPage
			&& !showFirstSurveyPendingVerificationPage
		) {
			window.trialMemberPopupShowTimer = setTimeout(() => {
				openPreconditionModal();
				updateMember({ hasSeenTrialMemberPopup: true });
				clearTimeout(window.trialMemberPopupShowTimer);
			}, 0);
		}
	}, [
		affiliateMember,
		hasSeenTrialMemberPopup,
		location.pathname,
		matchEvents,
		modalIsOpen,
		openPreconditionModal,
		showLeadLandingPage,
		showFirstSurveyPendingVerificationPage,
		showTrialMemberModal,
		trialMember,
		updateMember
	]);

	// Clear the timer if the trial member popup has already been seen by other means.
	useEffect(() => {
		if (hasSeenTrialMemberPopup) {
			const seenTime = +(localStorage.getItem(SEEN_TRIAL) || 0);
			if (isNotToday(seenTime)) {
				window.localStorage.setItem(SEEN_TRIAL, Date.now());
			}
			if (window.trialMemberPopupShowTimer !== null) {
				clearTimeout(window.trialMemberPopupCallbackTimer);
				clearTimeout(window.trialMemberPopupShowTimer);
			}
		}
	}, [hasSeenTrialMemberPopup]);

	const postSurveyReferralVerificationModalInitialTrackingObject = {
		category: "postsurveyreferralmodal",
		label: "timed",
		locale: locale,
	};

	const closePostSurveyReferralVerificationModalEventHandler = () => {
		let trackingObject = {
			...postSurveyReferralVerificationModalInitialTrackingObject,
			action: "close",
			queryParams,
		};

		if ("undefined" !== typeof window) {
			if (window.sermo.temp) {
				trackingObject.postId = window.sermo.temp.expandedPostId;
				trackingObject.adId = window.sermo.temp.expandedAdId;

				if (window.sermo.temp.expandedPostId) {
					trackingObject.expanded = true;
				}
			}
		}

		trackEvent(trackingObject);

		closeModal("post-survey-referral");
		setShowPostSurveyReferralVerificationModal(false);
	};

	// Start the timer to show the post survey referral verification popup after x seconds.
	useEffect(() => {
		if (
			isPostSurveyReferral
			&& !showLeadLandingPage
			&& !showFirstSurveyPendingVerificationPage
			&& !location.pathname.toLowerCase().startsWith("/firsttimelogin")
			&& showPostSurveyReferralVerificationModal
			&& !modalIsOpen
		) {
			window.postSurveyReferralVerificationPopupShowTimer = setTimeout(() => {
				openModal({
					[EUIKey.MODAL_CLOSE_METHOD]: closePostSurveyReferralVerificationModalEventHandler,
					[EUIKey.MODAL_COMPONENT]: <PostSurvey/>,
					[EUIKey.MODAL_LABEL]: "post-survey-referral",
				});

				updateMember({ hasSeenPostSurveyReferralPopup: true });
				clearTimeout(window.postSurveyReferralVerificationPopupShowTimer);
			}, 5000);
		}
	}, [
		isPostSurveyReferral,
		modalIsOpen,
		showLeadLandingPage,
		showFirstSurveyPendingVerificationPage,
	]);

	// Clear the timer if the post survey referral verification popup has already been seen by other means.
	useEffect(() => {
		if (hasSeenPostSurveyReferralPopup) {
			if (window.postSurveyReferralVerificationPopupShowTimer !== null) {
				clearTimeout(window.postSurveyReferralVerificationPopupShowTimer);
			}
		}
	}, [hasSeenPostSurveyReferralPopup]);

	useEffect(() => {
		if (
			privacyPolicy?.show
			&& !modalIsOpen
		) {
			openModal({
				[EUIKey.MODAL_CAN_CLOSE]: false,
				[EUIKey.MODAL_COMPONENT]: (
					<PrivacyPolicy />
				),
				[EUIKey.MODAL_LABEL]: "privacy-policy",
				[EUIKey.MODAL_OVER_THE_TOP]: true,
				[EUIKey.MODAL_SUBTYPE]: "with-icon",
			});
		}
	}, [
		modalIsOpen,
		privacyPolicy?.show,
	]);

	// RWP modal
	// const [rwpModalData, setRwpModalData] = useState(null);
	// const [rwpModalTimeout, setRwpModalTimeout] = useState(null);
	// const targetedPathRegex = /^\/(feed|rate|surveys|messages|profile)/;
	// const isTargetedPath = targetedPathRegex.test(location.pathname.toLowerCase());
	// const label = "run-with-peter";
	// const rwpCookie = getCookie(label);
	// const endDateForRwpModal = "June 9, 2024";
	// const startDateForRwpModal = "April 17, 2024";
	// const cookieExpirationDurationInDays = 7;
	// const timeoutDuration = 30 * 1000; // 30 seconds

	// const handleValidateBeforeEndDate = () => {
	// 	const today = new Date();
	// 	const end = new Date(endDateForRwpModal);
	// 	return today < end;
	// };

	// const handlevalidateIsStartDate = () => {
	// 	const today = new Date();
	// 	const start = new Date(startDateForRwpModal);
	// 	var startPlusOne = new Date();
	// 	startPlusOne.setDate(today.getDate()+1);
	// 	return today > start && today < startPlusOne;
	// };

	// const handleCloseRwpModal = () => {
	// 	closeModal(label);
	// 	trackEvent({
	// 		category: "runwithpeter",
	// 		label: "timed-modal",
	// 		action: "close",
	// 	});
	// };

	// const handleOpenRwpModal = () => {
	// 	openModal({
	// 		[EUIKey.MODAL_CLOSE_METHOD]: handleCloseRwpModal,
	// 		[EUIKey.MODAL_COMPONENT]: <RunWithPeterModal />,
	// 		[EUIKey.MODAL_LABEL]: label,
	// 		[EUIKey.MODAL_SUBTYPE]: label,
	// 	});
	// 	setCookie(label, true, cookieExpirationDurationInDays);
	// 	trackEvent({
	// 		category: "runwithpeter",
	// 		label: "timed-modal",
	// 		action: "open",
	// 	});
	// };

	// const rwpCookieReset = getCookie("run-with-peter-reset");

	// useEffect(() => {
	// 	const isBeforeEndDate = handleValidateBeforeEndDate();
	// 	const isStartDate = handlevalidateIsStartDate();

	// 	if ( isStartDate && !rwpCookieReset ) {
	// 		deleteCookie(label);
	// 		setCookie("run-with-peter-reset", true, 200);
	// 	}
	// 	if (
	// 		!rwpModalData
	// 		&& !rwpCookie
	// 		&& isBeforeEndDate
	// 		&& isTargetedPath
	// 	) {
	// 		apiEndpoint("runwithpeter/GetRunWithPeter")
	// 			.then((response) => {
	// 				if (response.ok) {
	// 					response.json().then((data) => {
	// 						setRwpModalData(data);
	// 					});
	// 				}
	// 			});
	// 	}
	// 	else if (
	// 		!rwpCookie
	// 		&& isBeforeEndDate
	// 		&& isTargetedPath
	// 		&& !modalIsOpen
	// 		&& rwpModalData
	// 		&& !rwpModalData?.isRegistered
	// 		&& !rwpModalTimeout
	// 	) {
	// 		setRwpModalTimeout(setTimeout(handleOpenRwpModal, timeoutDuration));
	// 	}
	// 	else if (
	// 		modalIsOpen
	// 		&& rwpModalTimeout
	// 	) {
	// 		clearTimeout(rwpModalTimeout);
	// 		setRwpModalTimeout(null);
	// 	}
	// }, [
	// 	isTargetedPath,
	// 	location.pathname,
	// 	modalIsOpen,
	// 	rwpCookie,
	// 	rwpModalData,
	// 	rwpModalTimeout,
	// ]);

	// used to show the post creator via query param
	// used by onboarding
	const togglePostEditor = useTogglePostEditor();

	useEffect(() => {
		if ( queryParams?.showPostCreator === "true" ) {
			togglePostEditor(true);
		}
	}, [queryParams]);

	return <AutomationRoutes />;
};

export default Automation;
