import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loggedOut } from "@frontend/mobile-app";
import { useTrackEvent } from "@frontend/tracking/tracking";
import { useApiEndpoint, useMemoizedContext } from "@hooks/Hooks";
import getTranslation from "@translation/translation";
import * as Styled from "./TrialMemberModal.styles";

export const TrialMemberModal = ({ closeHandler, trackingObject }) => {
	const navigate = useNavigate();
	const trackEvent = useTrackEvent();
	const apiEndpoint = useApiEndpoint();
	const { updateMember } = useMemoizedContext("member", ["hasSeenTrialMemberPopup"]);
	const [isValid, setIsValid] = React.useState(false);
	const [loading, setLoading] = useState(false);
	const [trackNewPasswordEntered, setTrackNewPasswordEntered] = useState(true);
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordValidationError, setNewPasswordValidationError] = useState([]);
	const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false);
	const [trialMemberConversionComplete, setTrialMemberConversionComplete] = useState(false);

	useEffect(() => {
		if (newPassword.length >= 8
			&& acceptedTermsAndConditions
		) {
			setIsValid(true);
		}
		else {
			setIsValid(false);
		}
	}, [newPassword, acceptedTermsAndConditions]);

	const getUpdatedTrackingObject = (props = {}) => {
		let updatedTrackingObject = {
			...trackingObject,
			...props,
		};

		if (window?.sermo?.temp) {
			updatedTrackingObject.postId = window.sermo.temp.expandedPostId;
			updatedTrackingObject.adId = window.sermo.temp.expandedAdId;

			if (window.sermo.temp.expandedPostId) {
				updatedTrackingObject.expanded = true;
			}

			if (window.sermo.temp.eventRegistrantToken) {
				updatedTrackingObject.eventRegistrantToken = window.sermo.temp.eventRegistrantToken;
				updatedTrackingObject.eventToken = window.sermo.temp.eventToken;
			}
		}

		return updatedTrackingObject;
	};

	useEffect(() => {
		const updatedTrackingObject = getUpdatedTrackingObject({ action: "open" });
		trackEvent(updatedTrackingObject);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleSubmitFormClick = (event) => {
		event.preventDefault();

		const updatedTrackingObject = getUpdatedTrackingObject({ action: "submit" });
		trackEvent(updatedTrackingObject);

		setLoading(true);

		let requestData = {
			newPassword: newPassword,
			acceptTermsAndConditions: acceptedTermsAndConditions,
			timeZoneOffset: -new Date()?.getTimezoneOffset(),
			timeZoneId: new Intl.DateTimeFormat()?.resolvedOptions()?.timeZone,
		};

		let endpointUrl = "settings/completeTrialMembership";

		apiEndpoint(endpointUrl, requestData).then((dataResponse) => {
			dataResponse.json().then((body) => {
				if (dataResponse.ok) {
					const updatedTrackingObject = getUpdatedTrackingObject({
						label: "complete",
						area: "submitpassword",
					});
					trackEvent(updatedTrackingObject);

					updateMember({
						rights: body.rights,
						hasAcceptedTermsAndConditions: acceptedTermsAndConditions,
					});

					// We need this endpoint invoked as asp.net core does
					// not properly update the claims identity even after properly authentication,
					// resulting in the anti-forgery token not being valid.
					// A seperate call to the server fixes this. This will be fixed once we get to implement oauth.
					apiEndpoint("system/keepalive");

					apiEndpoint("system/getmemberdata").then((dataResponse) => {
						setLoading(false);
						if (dataResponse.ok) {
							dataResponse.json().then((body) => {
								updateMember({
									...body,
									hasSeenTrialMemberPopup: true,
								});
								if (body.readOnlyMember) {
									apiEndpoint("login/signout").then((response) => {
										if (response.ok) {
											// If running in mobile app, signal native code to stop push notifications.
											loggedOut();
											navigate("/read-only");
										}
									});
								}
								else {
									setTrialMemberConversionComplete(true);
								}
							});
						}
					});
				}
				else {
					setLoading(false);
					setTrackNewPasswordEntered(true);
					if (body.fields?.newPassword) {
						setNewPasswordValidationError([body.fields.newPassword.join("<br />")]);
					}
				}
			});
		});
	};

	const handleCloseModalClick = (event) => {
		const updatedTrackingObject = getUpdatedTrackingObject({
			label: "close-modal",
			action: "click",
		});
		trackEvent(updatedTrackingObject);
		closeHandler(event);
	};

	const handlePasswordChange = (event) => {
		setNewPassword(event.target.value);

		if (trackNewPasswordEntered) {
			setTrackNewPasswordEntered(false);
			const updatedTrackingObject = getUpdatedTrackingObject({ action: "enter-password" });
			trackEvent(updatedTrackingObject);
		}
	};

	const handleTermsAndConditionsChange = (event) => {
		setAcceptedTermsAndConditions(event.target.checked);
	};

	const handleLoginClick = () => {
		const updatedTrackingObject = getUpdatedTrackingObject({
			label: "login",
			action: "click",
		});
		trackEvent(updatedTrackingObject);
		apiEndpoint("login/signout").then(response => {
			response.json().then(body => {
				if (response.ok) {
					loggedOut();
					navigate("/login");
				} else {
					console.log("Error logging out!");
				}
			});
		});
	};

	return (
		<Styled.TrialMemberModalWrap>
			<Styled.TopImage/>
			{
				!trialMemberConversionComplete && (
					<Styled.ContentWrap>
						<Styled.HeaderText>
							{getTranslation("frontend.modals.trialMember.headerText", true)}
						</Styled.HeaderText>
						<Styled.BodyText>
							{getTranslation("frontend.modals.trialMember.bodyText1", true)}
							<Styled.Spacer/>
							{getTranslation("frontend.modals.trialMember.bodyText2", true)}
						</Styled.BodyText>
						<Styled.Password
							errors={newPasswordValidationError}
							toggleOn={getTranslation("frontend.generics.show", true)}
							toggleOff={getTranslation("frontend.generics.hide", true)}
							onChange={handlePasswordChange}
							placeholder={getTranslation("frontend.modals.trialMember.passwordPlaceholder", true)}
						/>
						<Styled.ConfirmCheckbox
							label={getTranslation("frontend.modals.trialMember.acceptTermsAndConditions")}
							onChange={handleTermsAndConditionsChange}
						/>
						<Styled.FooterWrap>
							<Styled.FooterText>
								{getTranslation("frontend.login.memberAlready", true)}
								&nbsp;
								<Styled.ClickableText onClick={handleLoginClick}>
									{getTranslation("frontend.login.signInPage.loginSubmitButtonText", true)}
								</Styled.ClickableText>
							</Styled.FooterText>
							<Styled.FooterButton
								disabled={!isValid}
								onClick={handleSubmitFormClick}
								processing={loading}
							>
								{getTranslation("frontend.generics.submit", true)}
							</Styled.FooterButton>
						</Styled.FooterWrap>
					</Styled.ContentWrap>
				)
			}
			{
				trialMemberConversionComplete && (
					<Styled.ContentWrap>
						<Styled.HeaderText>
							{getTranslation("frontend.modals.trialMember.confirmationIntroTitle", true)}
						</Styled.HeaderText>
						<Styled.BodyText>
							{getTranslation("frontend.modals.trialMember.confirmationIntroDescription", true)}
						</Styled.BodyText>
						<Styled.SuccessFooterWrap>
							<Styled.FooterButton onClick={handleCloseModalClick}>
								{getTranslation("frontend.modals.trialMember.confirmationStartButtonText", true)}
							</Styled.FooterButton>
						</Styled.SuccessFooterWrap>
					</Styled.ContentWrap>
				)
			}
		</Styled.TrialMemberModalWrap>
	);
}

TrialMemberModal.propTypes = {
	closeHandler: PropTypes.func,
	trackingObject: PropTypes.object,
};

TrialMemberModal.defaultProps = {
	closeHandler: () => {},
	trackingObject: {},
};