import { datadogRum } from "@datadog/browser-rum";
import React, { useState, useEffect } from "react";
import { Loading } from "@components/Helpers/Helpers";
import { ReverificationWrap } from "@components/Register/Register.styles";
import { useFetch } from "@hooks/Hooks";
import config from "./components/Reverification/config";

const Reverification = () => {
	const [currentStep, setCurrentStep] = useState(null);
	const [reverificationData, setReverificationData] = useState(false);
	const [data, loading, error] = useFetch("api/reverification/getdata");

	useEffect(() => {
		if (data && !loading && !error) {
			setReverificationData(data);
		}
	}, [data, loading, error]);

	useEffect(() => {
		if (reverificationData) {
			// TODO: handle new field emailVerified ?
			if (reverificationData.mobileNumber) {
				let number = reverificationData.mobileNumber;

				if (number[0] === "+") {
					const selectedCountry = reverificationData.availableCountries.find(
						(c) => c.id.toLowerCase() === reverificationData.selectedCountryCode.toLowerCase()
					);
					number = number.substring((selectedCountry.telephoneCode + "").length + 1);

					reverificationData.mobileNumber = number;
				}
			}

			if (reverificationData.currentStep !== currentStep) {
				setCurrentStep(reverificationData.currentStep);
			}
		}
	}, [reverificationData]);

	useEffect(() => {
		const env = window?.sermo?.env;
		if (env === "production" || env === "staging") {
			// -- Start new view to isolate the recording -- //
			datadogRum.startView({ name: "/reverification" })

			// -- Start new session forcing record replay -- //
			datadogRum.startSessionReplayRecording({ force: true });

			return () => {
				datadogRum.stopSessionReplayRecording();
				datadogRum.startView();
			}
		}
	}, []);

	const updateReverificationData = (data) => {
		setReverificationData({
			...reverificationData,
			...data,
		});
	};

	if (loading) {
		return (
			<ReverificationWrap>
				<Loading />
			</ReverificationWrap>
		);
	}

	if (!reverificationData || error || !currentStep || !Object.prototype.hasOwnProperty.call(config, currentStep)) {
		return null;
	}

	const { Component } = config[currentStep];

	return (
		<>
			{
				Component && (
					<Component
						data={reverificationData}
						updateData={updateReverificationData}
						endpoint="reverification"
					/>
				)
			}
		</>
	);
};

export default Reverification;
